import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import { useTheme } from '@mui/system';

const userTestimonials = [
  {
    avatar: <Avatar alt="Joe Schaefer" src="./assets/images/avatars/joe-schaefer.png" />,
    name: 'Joe Schaefer',
    occupation: 'VP, Lead Cloud Engineer',
    testimonial:
      "Dan is an impact player on any team... Approachable, down-to-earth, genuine, highly skilled and highly motivated to deliver value every single day.",
  },
  {
    avatar: <Avatar alt="Anthony Lucillo" src="./assets/images/avatars/anthony-lucillo.png" />,
    name: 'Anthony Lucillo',
    occupation: 'Senior Site Reliability Engineer',
    testimonial:
      "[thagardHus] brought a lot of value from an engineering perspective, but more important was [their] leadership and vision for continuously improving upon systems and processes while also elevating everyone around... empowering them to deliver brilliant solutions to incredible problems.",
  },
  {
    avatar: <Avatar alt="Jim Garrett" src="./assets/images/avatars/jim-garrett.png" />,
    name: 'Jim Garrett',
    occupation: 'CTO',
    testimonial:
      '[Dan] is a very high-end individual with a great deal of talent and drive. He was constantly pushing our organization toward better standards and uniformity.',
  },
];

const whiteLogos = [
  './assets/images/logos/atlas-health-logo-white.svg',
  './assets/images/logos/doxy-me-logo-white.png',
  './assets/images/logos/magic-leap-logo-white.png',
  './assets/images/logos/citrix-logo-white.png',
  './assets/images/logos/corellium-logo-white.png',
];

const darkLogos = [
  './assets/images/logos/atlas-health-logo-black.svg',
  './assets/images/logos/doxy-me-logo-white.png',
  './assets/images/logos/magic-leap-logo-black.png',
  './assets/images/logos/citrix-logo-black.png',
  './assets/images/logos/corellium-logo-black.png',
];

const logoStyle = {
  width: '64px',
  opacity: 0.3,
};

export default function Testimonials() {
  const theme = useTheme();
  const logos = theme.palette.mode === 'light' ? darkLogos : whiteLogos;

  return (
    <Container
      id="testimonials"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        <Typography
          component="h2"
          variant="h4"
          gutterBottom
          sx={{ color: 'text.primary' }}
        >
          Testimonials
        </Typography>
        <Typography variant="body1" sx={{ color: 'text.secondary' }}>
          Our team brings decades of hands-on experience across industries like finance, healthcare, and technology, working on the front lines of software engineering, cloud architecture, and cybersecurity. We’ve implemented solutions that help companies grow while staying protected in a digital world. This deep knowledge informs everything we do, helping us deliver results that are secure, scalable, and efficient.
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {userTestimonials.map((testimonial, index) => (
          <Grid size={{ xs: 12, sm: 6, md: 4 }} key={index} sx={{ display: 'flex' }}>
            <Card
              variant="outlined"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                flexGrow: 1,
              }}
            >
              <CardContent>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{ color: 'text.secondary' }}
                >
                  {testimonial.testimonial}
                </Typography>
              </CardContent>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <CardHeader
                  avatar={testimonial.avatar}
                  title={testimonial.name}
                  subheader={testimonial.occupation}
                />
                <img
                  src={logos[index]}
                  alt={`Logo ${index + 1}`}
                  style={logoStyle}
                />
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        <Typography variant="body1" sx={{ color: 'text.secondary'}}>
          At thagardHus, we provide reliable, innovative, and secure solutions designed to exceed expectations. Whether it’s scaling infrastructure, enhancing software, or protecting your data, we’re your trusted partners every step of the way.
        </Typography>
      </Box>
    </Container>
  );
}
